exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-settings-tsx": () => import("./../../../src/pages/account-settings.tsx" /* webpackChunkName: "component---src-pages-account-settings-tsx" */),
  "component---src-pages-checklists-[id]-settings-tsx": () => import("./../../../src/pages/checklists/[id]/settings.tsx" /* webpackChunkName: "component---src-pages-checklists-[id]-settings-tsx" */),
  "component---src-pages-checklists-[id]-tsx": () => import("./../../../src/pages/checklists/[id].tsx" /* webpackChunkName: "component---src-pages-checklists-[id]-tsx" */),
  "component---src-pages-checklists-new-tsx": () => import("./../../../src/pages/checklists-new.tsx" /* webpackChunkName: "component---src-pages-checklists-new-tsx" */),
  "component---src-pages-design-system-tsx": () => import("./../../../src/pages/design-system.tsx" /* webpackChunkName: "component---src-pages-design-system-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-todolists-[id]-tsx": () => import("./../../../src/pages/todolists/[id].tsx" /* webpackChunkName: "component---src-pages-todolists-[id]-tsx" */)
}

